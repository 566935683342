import { HttpHeaders } from '@angular/common/http';
import { Config, CvxClaimsPrincipal } from '@cvx/cal';
import { CalInterceptorConfiguration } from 'src/app/auth/cal.interceptor.config';

const calConfig: Config = {
  "autoSignIn": false,
  "popupForLogin": false,
  "instance": "https://login.microsoftonline.com/",
  "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  "clientId": "e9366d4d-41a8-4f78-9f51-25cf354443bb", 
  "cacheLocation": "localStorage",
  // redirectUri will need to match a redirectUri setup in your app registration
  "redirectUri": "https://dtms-dev.azure.chevron.com/",
  "oidcScopes": ["openid", "profile", "user.read","offline_access"],
  "graphScopes": ["User.Read", "User.Read.All", "Directory.Read.All"]
};

const calInterceptorConfig: CalInterceptorConfiguration = {
  consentScopes: ["https://dtms-test.azure.chevron.com/user_impersonation"]
};

const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json' )
      .append('Access-Control-Allow-Headers', 'Content-Type')
      .append('Access-Control-Allow-Methods', 'GET')
      .append('Access-Control-Allow-Methods', 'POST')
      .append('Access-Control-Allow-Methods', 'PUT')
      .append('Access-Control-Allow-Methods', 'DELETE')
      .append('Access-Control-Allow-Origin', 'https://dtms-dev.azure.chevron.com/');

//TODO: update uri for domain 'https://dtmsapi-dev.azure.chevron.com/api/
export const environment = {
  production: false,
  appTitle: 'digital Transportation Management System [Dev]',
  trayApi: 'https://dtmsapi-dev.azure.chevron.com/api/tray',
  api: 'https://dtmsapi-dev.azure.chevron.com/api/',
  calConfig: calConfig,
  calInterceptorConfig: calInterceptorConfig,
  headers: headers,
  appInsights: {
    instrumentationKey: 'ab57f8f7-d40e-4c44-87da-db5990f0518b'
  },
  account: '',
  profile: new CvxClaimsPrincipal(),
  origin: 'GAO',
  destination:'GAO',
  adminRole: '',
  manifestingRole: 'dtms-manifest-users',
  readonlyRole: 'dtms-read-only'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// see https://stackoverflow.com/c/chevron/questions/1702
